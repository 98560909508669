import React from "react";
import "../stylesheets/Admin.css";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from "../utils";
import config from "../config.json";

function Admin() {
  const [report, setReport] = React.useState([]);

  const [searchParams] = useSearchParams();
  const reportCode = searchParams.get("code");

  const [companies, setCompanies] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      if (config.api.offline) {
        setCompanies([
          { id: 1, name: "Firma 1", admin: 1 },
          { id: 2, name: "Firma 2", admin: 1 },
          { id: 3, name: "Firma 3", admin: 1 },
        ]);
        return;
      }
      global.setLoadingVisible(true);
      try {
        const result = await fetch(`${config.api.url}/companies`);
        if (result.ok) setCompanies(await result.json());
        else console.error("Failed to fetch companies", result);
      } catch (result) {
        console.error("Failed to fetch companies", result);
      }
      global.setLoadingVisible(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (config.api.offline) {
        setReport({
          gid: 3,
          company: 1,
          companyRelation: 3,
          id: 3,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          contact:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          attachments: ["attachment1.pdf", "attachment2.pdf"],
          status: 2,
          date: Date.now(),
        });
        return;
      }
      global.setLoadingVisible(true);
      try {
        const result = await fetch(`${config.api.url}/report/${reportCode}`);
        if (result.ok) setReport(await result.json());
        else console.error("Failed to fetch report", result);
      } catch (result) {
        console.error("Failed to fetch report", result);
      }
      global.setLoadingVisible(false);
    })();
  }, [reportCode]);

  const statusIcons = [
    <FontAwesomeIcon icon={faClock} className="Pending" />,
    <FontAwesomeIcon icon={faXmark} className="Rejected" />,
    <FontAwesomeIcon icon={faCheck} className="Approved" />,
  ];

  React.useEffect(() => global.setLoadingVisible(false), []);

  return (
    <div className="Admin">
      <div className="Side-panel">
        <h1>Podgląd statusu zgłoszenia</h1>

        <div className="Tracker">
          Poniższy link pozwoli Ci na śledzenie statusu zgłoszenia:
          <br />
          <a href={document.location.href}>
            {document.location.href}
          </a>
          <br />
          Zapisz go, aby móc sprawdzić postęp sprawy.
        </div>

        <a href="/">Utwórz nowe zgłoszenie</a>
      </div>
      <div className="Main-panel">
        <div className="Content">
          <div className="Report-view">
            <div className="Report-row">
              <p className="Report-id">
                GID: <b>{formatNumber(report?.gid, 3, false)}</b>
                <br />
                <div className="mobile spacing"></div>
                Lokalizacja: <b>{(companies.filter(company => company.id === ((report?.company) || 1))[0] || { name: "Błąd" }).name}</b>
              </p>
              <p className="Report-status">
                Status:{" "}
                <b>
                  {report?.status === 0
                    ? "Oczekujące"
                    : report?.status === 1
                      ? "Odrzucone"
                      : "Zaakceptowane"}{" "}
                  <span className={`Status-icon`}>
                    {statusIcons[report?.status]}
                  </span>
                </b>
                <br />
                <div className="mobile spacing"></div>
                Data utworzenia:{" "}
                <b>
                  {new Date(report?.date).toLocaleString("pl-PL", {
                    timeZone: "UTC",
                  })}
                </b>
              </p>
              <p className="Report-id">
                ID: <b>{formatNumber(report?.id, 3, false)}</b>
                <br />
                <div className="mobile spacing"></div>
                Relacja z firmą: <b>{(["Wystąpił błąd", "Pracownik", "Dostawca", "Inne"])[+report?.companyRelation]}</b>
              </p>
            </div>
            <div className="Report-row Report-content-row">
              <div className="Report-content">
                <b>Treść zgłoszenia:</b>
                <br />
                <pre>{report?.content}</pre>
                <br />
                {
                  report?.attachments && report?.attachments.length > 0 && (
                    <>
                      <b>Załączniki:</b>
                      <br />
                      <div className="Report-attachments">
                        {
                          report.attachments.map((attachment, i) => (
                            <>
                              <a key={i} href={`/dn/attachments/${attachment}`}>{attachment}</a><br />
                            </>
                          ))
                        }
                      </div>
                    </>
                  )
                }
                <br />
                {
                  report?.comments && report?.comments.length > 0 &&
                  report?.comments.map((comment, i) => (<pre key={comment.id}>
                    <b>Komentarz {comment.id}: </b>
                    {comment.content}
                  </pre>))
                }
              </div>
              {report?.contact && (
                <div className="Report-contact">
                  <b>Informacje kontaktowe:</b>
                  <br />
                  <pre>{report?.contact}</pre>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="Footer">
          <a href="/dn/privacy.pdf">Polityka prywatności</a>
          <a href="/dn/report.pdf">Procedura zgłaszania</a>
          <a href="/dn/report.pdf">Instrukcja zgłaszania</a>
          <a className="mobile" href="/">
            Użytkownik
          </a>
        </div>
      </div>
    </div>
  );
}

export default Admin;
