import React from "react";
import "../stylesheets/Admin.css";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { formatNumber } from "../utils";
import config from "../config.json";

function Admin() {
  const [companies, setCompanies] = React.useState([]);
  const [mails, setMails] = React.useState([]);
  const [reports, setReports] = React.useState([]);
  const reloadReports = async () => {
    if (config.api.offline) {
      setReports([
        {
          gid: 3,
          company: 1,
          companyRelation: 3,
          id: 3,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          contact:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          attachments: ["attachment1.pdf", "attachment2.pdf"],
          status: 2,
          date: Date.now(),
        },
        {
          gid: 2,
          company: 1,
          companyRelation: 2,
          id: 2,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          status: 1,
          date: Date.now(),
        },
        {
          gid: 1,
          company: 1,
          companyRelation: 1,
          id: 1,
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.",
          status: 0,
          date: Date.now() - 1000 * 60 * 60 * 24 * 5,
        },
      ]);
      return;
    }
    global.setLoadingVisible(true);
    try {
      const result = await fetch(`${config.api.url}/reports`);
      if (result.ok) setReports(await [...(await result.json())].reverse());
      else if (result.status === 401) document.location.href = "/auth";
      else console.error("Failed to fetch reports", result);
    } catch (result) {
      if (result.status === 401) document.location.href = "/auth";
      else console.error("Failed to fetch reports", result);
    }
    global.setLoadingVisible(false);
  };
  React.useEffect(() => {
    (async () => {
      if (config.api.offline) {
        setCompanies([
          { id: 1, name: "Firma 1", admin: 1, visible: true },
          { id: 2, name: "Firma 2", admin: 1, visible: true },
          { id: 3, name: "Firma 3", admin: 1, visible: true },
        ]);
        setMails([
          { id: 1, mail: "email1@service1.com", user: 1 },
          { id: 2, mail: "email2@service2.com", user: 1 },
          { id: 3, mail: "email3@service3.com", user: 1 },
        ]);
        return;
      }
      global.setLoadingVisible(true);
      try {
        const result = await fetch(`${config.api.url}/companies/all`);
        if (result.ok) setCompanies(await result.json());
        else console.error("Failed to fetch companies", result);
      } catch (result) {
        console.error("Failed to fetch companies", result);
      }
      try {
        const result = await fetch(`${config.api.url}/mails`);
        if (result.ok) setMails(await result.json());
        else console.error("Failed to fetch mails", result);
      } catch (result) {
        console.error("Failed to fetch mails", result);
      }
      global.setLoadingVisible(false);
    })();

    reloadReports();
  }, []);

  const statusIcons = [
    <FontAwesomeIcon icon={faClock} className="Pending" />,
    <FontAwesomeIcon icon={faXmark} className="Rejected" />,
    <FontAwesomeIcon icon={faCheck} className="Approved" />,
  ];

  const [comment, setComment] = React.useState("");
  const [passedData, setDataToPass] = React.useState({});
  const sites = {
    overview: {
      title: "Przegląd",
      content: (
        <div className="Statistics">
          <p className="Statistic">
            Liczba zgłoszeń: <b>{formatNumber(reports.length, 3, false)}</b>
          </p>
          <p className="Statistic">
            Pozytywnie rozpatrzonych:{" "}
            <b>
              {formatNumber(
                reports.filter((r) => r.status === 2).length,
                3,
                false
              )}
            </b>
          </p>
          <p className="Statistic">
            Odrzuconych:{" "}
            <b>
              {formatNumber(
                reports.filter((r) => r.status === 1).length,
                3,
                false
              )}
            </b>
          </p>
          <p className="Statistic">
            Oczekujących:{" "}
            <b>
              {formatNumber(
                reports.filter((r) => r.status === 0).length,
                3,
                false
              )}
            </b>
          </p>
        </div>
      ),
    },
    reports: {
      title: "Zgłoszenia",
      content: (
        <table>
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backdropFilter: "blur(5px)",
              }}
            >
              <th>GID</th>
              <th>ID</th>
              <th>Lokalizacja</th>
              <th className="Longest-cell">Treść</th>
              <th>Data zgłoszenia</th>
              <th className="Status-icon"></th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr
                key={report.gid}
                className="Report"
                onClick={async () => {
                  global.setLoadingVisible(true);
                  report.comments = (config.api.offline ? { comments: [{ id: 1, content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos." }] }
                    : await (await fetch(`${config.api.url}/report/${report.gid}?asAdmin=1`)).json()).comments;

                  goto("report", {
                    report,
                  });
                  global.setLoadingVisible(false);
                }}
              >
                <td data-label="GID" className="non-mobile">
                  {formatNumber(report.gid, 3, false)}
                </td>
                <td data-label="ID" className="non-mobile">
                  {formatNumber(report.id, 3, false)}
                </td>
                <td className="Status-icon mobile">
                  {statusIcons[report.status]}
                </td>
                <td data-label="Lokalizacja">
                  {(companies.filter(company => company.id === (report.company || 1))[0] || { name: "Błąd" }).name}
                </td>
                <td data-label="Treść" className="Longest-cell">
                  <span className="text-overflow-dynamic-container">
                    <span
                      className="text-overflow-dynamic-ellipsis"
                      title={report.content}
                    >
                      <div>{report.content}</div>
                    </span>
                  </span>
                </td>
                <td
                  data-label="Data zgłoszenia"
                  style={
                    new Date(report.date).getTime() + 1000 * 60 * 60 * 24 * 5 <= Date.now() &&
                      (report.status === 0 || report.status === "0")
                      ? { color: "#d00", fontWeight: "bold" }
                      : {}
                  }
                >
                  {new Date(report.date).toLocaleString("pl-PL", {
                    timeZone: "UTC",
                  })}
                </td>
                <td className="Status-icon non-mobile">
                  {statusIcons[report.status]}
                </td>
              </tr>
            ))}
            <tr className="Report Mock">
              <td className="non-mobile">000</td>
              <td className="non-mobile">000</td>
              <td className="Status-icon mobile">
                <FontAwesomeIcon icon={faClock} className="Pending" />
              </td>
              <td>Bielsk Podlaski, ul. Mickiewicza 11</td>
              <td className="Longest-cell">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam, quos.
              </td>
              <td className="Longest-cell">01.01.2021, 12:00:00</td>
              <td className="Status-icon non-mobile">
                <FontAwesomeIcon icon={faClock} className="Pending" />
              </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    report: {
      title: "Zgłoszenie",
      hidden: true,
      fake: "reports",
      content: (
        <div className="Report-view">
          <div className="Report-row">
            <p className="Report-id">
              GID: <b>{formatNumber(passedData.report?.gid, 3, false)}</b>
              <br />
              <div className="mobile spacing"></div>
              Lokalizacja: <b>{(companies.filter(company => company.id === ((passedData.report?.company) || 1))[0] || { name: "Błąd" }).name}</b>
            </p>
            <p className="Report-status">
              Status:{" "}
              <b>
                {passedData.report?.status === 0
                  ? "Oczekujące"
                  : passedData.report?.status === 1
                    ? "Odrzucone"
                    : "Zaakceptowane"}{" "}
                <span className={`Status-icon`}>
                  {statusIcons[passedData.report?.status]}
                </span>
              </b>
              <br />
              <div className="mobile spacing"></div>
              Data utworzenia:{" "}
              <b>
                {new Date(passedData.report?.date).toLocaleString("pl-PL", {
                  timeZone: "UTC",
                })}
              </b>
            </p>
            <p className="Report-id">
              ID: <b>{formatNumber(passedData.report?.id, 3, false)}</b>
              <br />
              <div className="mobile spacing"></div>
              Relacja z firmą: <b>{(["Wystąpił błąd", "Pracownik", "Dostawca", "Inne"])[+passedData.report?.companyRelation]}</b>
            </p>
          </div>
          <div className="Report-row Report-content-row">
            <div className="Report-content">
              <b>Treść zgłoszenia:</b>
              <br />
              <pre>{passedData.report?.content}</pre>
              <br />
              {
                passedData.report?.attachments && passedData.report?.attachments.length > 0 && (
                  <>
                    <b>Załączniki:</b>
                    <br />
                    <div className="Report-attachments">
                      {
                        passedData.report?.attachments.map((attachment, i) => (
                          <>
                            <a key={i} href={`/dn/attachments/${attachment}`}>{attachment}</a><br />
                          </>
                        ))
                      }
                    </div>
                  </>
                )
              }
              <br />
              {
                passedData.report?.comments && passedData.report?.comments.length > 0 &&
                passedData.report?.comments.map((comment, i) => (<pre key={comment.id}>
                  <b>Komentarz {comment.id}: </b>
                  {comment.content}
                </pre>))
              }
              {passedData.report?.status === 0 && (
                <>
                  <div
                    className="textarea Comment-input"
                    contentEditable="plaintext-only"
                    placeholder="Napisz komentarz"
                    value={comment}
                    onInput={(e) => {
                      setComment(e.currentTarget.textContent);
                    }}
                  />
                  <button className="Comment-button" onClick={(e) => {
                    if (config.api.offline) return;
                    global.setLoadingVisible(true);
                    fetch(`${config.api.url}/report/comment`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        report: passedData.report?.gid,
                        content: comment,
                      }),
                    }).then(async (result) => {
                      if (result.status === 401) document.location.href = "/auth";
                      else if (result.ok) {
                        let newData = { ...passedData };
                        newData.report.comments.push({ id: (await result.json()).id, content: comment });
                        setDataToPass(newData);
                        setComment("");
                      } else
                        console.error("Failed to fetch reports", result);
                      global.setLoadingVisible(false);
                    });
                  }}>Wyślij</button>
                </>
              )}
            </div>
            {passedData.report?.contact && (
              <div className="Report-contact">
                <b>Informacje kontaktowe:</b>
                <br />
                <pre>{passedData.report?.contact}</pre>
              </div>
            )}
          </div>
          {passedData.report?.status === 0 && (
            <div className="Report-row Report-buttons">
              <button
                onClick={() =>
                  global.ask({
                    question:
                      "Czy na pewno chcesz zaakceptować zgłoszenie? Po zatwierdzeniu dalsza modyfikacja będzie niemożliwa.",
                    buttons: [
                      {
                        name: "Tak",
                        action: async () => {
                          if (config.api.offline) return;
                          global.setLoadingVisible(true);
                          try {
                            const result = await fetch(
                              `${config.api.url}/report/status`,
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  gid: passedData.report?.gid,
                                  status: 2,
                                }),
                              }
                            );
                            if (result.status === 401)
                              document.location.href = "/auth";
                            else if (result.ok)
                              await reloadReports();
                            else
                              console.error("Failed to fetch reports", result);
                          } catch (result) {
                            if (result.status === 401)
                              document.location.href = "/auth";
                            else
                              console.error("Failed to fetch reports", result);
                          }
                          global.setLoadingVisible(false);
                          goto("reports", {});
                        },
                      },
                      {
                        name: "Nie",
                        action: () => { },
                      },
                    ],
                  })
                }
              >
                Zaakceptuj
              </button>
              <button
                onClick={() =>
                  global.ask({
                    question:
                      "Czy na pewno chcesz odrzucić zgłoszenie? Po zatwierdzeniu dalsza modyfikacja będzie niemożliwa.",
                    buttons: [
                      {
                        name: "Tak",
                        action: async () => {
                          if (config.api.offline) return;
                          global.setLoadingVisible(true);
                          try {
                            const result = await fetch(
                              `${config.api.url}/report/status`,
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  gid: passedData.report?.gid,
                                  status: 1,
                                }),
                              }
                            );
                            if (result.status === 401)
                              document.location.href = "/auth";
                            else if (result.ok)
                              await reloadReports();
                            else
                              console.error("Failed to fetch reports", result);
                          } catch (result) {
                            if (result.status === 401)
                              document.location.href = "/auth";
                            else
                              console.error("Failed to fetch reports", result);
                          }
                          global.setLoadingVisible(false);
                          goto("reports", {});
                        },
                      },
                      {
                        name: "Nie",
                        action: () => { },
                      },
                    ],
                  })
                }
              >
                Odrzuć
              </button>
            </div>
          )}
        </div>
      ),
    },
    mailingList: {
      title: "Powiadomienia",
      content: <>
        <h1>Lista adresów e-mail, na które przyjdą powiadomienia, gdy pojawi się nowe zgłoszenie:</h1>
        {mails.map(mail => (
          <div className="Company-row" key={mail.mail}>
            <p>{mail.mail}</p><a href="/" onClick={async (e) => {
              e.preventDefault();

              if (config.api.offline) return;
              global.setLoadingVisible(true);
              const result = await fetch(
                `${config.api.url}/mail/remove`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: mail.id,
                  }),
                }
              ).catch((result) => {
                if (result.status === 401)
                  document.location.href = "/auth";
                else
                  console.error("Deletion failed", result);
              });
              if (result.status === 401)
                document.location.href = "/auth";
              else if (result.ok) {
                setMails(mails.filter(m => m.id !== mail.id));
              } else
                console.error("Deletion failed", result);
              global.setLoadingVisible(false);
            }}>Usuń</a>
          </div>
        ))}
        <div className="spacing"><br /></div>
        <div className="spacing"><br /></div>
        <div className="Company-row">
          <h1 className="non-mobile">Dodaj nowy adres e-mail:</h1><div className="Company-new"><input id="mail" name="mail" placeholder="Adres e-mail" /><a href="/" onClick={async (e) => {
            e.preventDefault();

            if (config.api.offline) return;
            global.setLoadingVisible(true);
            const result = await fetch(
              `${config.api.url}/mail/add`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  mail: document.getElementById("mail").value,
                }),
              }
            ).catch((result) => {
              if (result.status === 401)
                document.location.href = "/auth";
              else
                console.error("Creation failed", result);
            });
            if (result.status === 401)
              document.location.href = "/auth";
            else if (result.ok) {
              setMails([...mails, { id: (await result.json()).id, mail: document.getElementById("mail").value }]);
              document.getElementById("mail").value = "";
            } else
              console.error("Creation failed", result);
            global.setLoadingVisible(false);
          }}>Dodaj</a></div>
        </div>
      </>
    },
    companies: {
      title: "Lokalizacje",
      content: <>
        <h1>Lokalizacje:</h1>
        {companies.filter(c => c.visible).map(company => (
          <div className="Company-row" key={company.name}>
            <p>{company.name}</p><a href="/" onClick={(e) => {
              e.preventDefault();

              global.ask({
                question: `Czy na pewno chcesz usunąć lokalizację ${company.name}?`,
                buttons: [
                  {
                    name: "Tak",
                    action: async () => {
                      if (config.api.offline) return;
                      global.setLoadingVisible(true);
                      const result = await fetch(
                        `${config.api.url}/company/delete`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            id: company.id,
                          }),
                        }
                      ).catch((result) => {
                        if (result.status === 401)
                          document.location.href = "/auth";
                        else
                          console.error("Deletion failed", result);
                      });
                      if (result.status === 401)
                        document.location.href = "/auth";
                      else if (result.ok) {
                        let newCompanies = [...companies];
                        newCompanies[companies.indexOf(company)].visible = false;
                        setCompanies(newCompanies);
                      } else
                        console.error("Deletion failed", result);
                      global.setLoadingVisible(false);
                    },
                  },
                  {
                    name: "Nie",
                    action: () => { },
                  },
                ]
              });
            }}>Usuń</a>
          </div>
        ))}
        <div className="spacing"><br /></div>
        <div className="spacing"><br /></div>
        <div className="Company-row">
          <h1 className="non-mobile">Dodaj nową lokalizację:</h1><div className="Company-new"><input id="address" name="address" placeholder="Adres / Nazwa" /><a href="/" onClick={async (e) => {
            e.preventDefault();

            if (config.api.offline) return;
            global.setLoadingVisible(true);
            const result = await fetch(
              `${config.api.url}/company/create`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: document.getElementById("address").value,
                }),
              }
            ).catch((result) => {
              if (result.status === 401)
                document.location.href = "/auth";
              else
                console.error("Creation failed", result);
              global.setLoadingVisible(false);
            });
            if (result.status === 401)
              document.location.href = "/auth";
            else if (result.ok) {
              setCompanies([...companies, { id: (await result.json()).id, name: document.getElementById("address").value, visible: true }]);
              document.getElementById("address").value = "";
            } else
              console.error("Creation failed", result);
            global.setLoadingVisible(false);
          }}>Dodaj</a></div>
        </div>
      </>
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const siteParam = searchParams.get("site");
  const siteFromParams =
    sites[siteParam]?.content && !sites[siteParam]?.hidden && siteParam;
  const defaultSite = siteFromParams || "overview";
  const [currentSite, setCurrentSite] = React.useState(defaultSite);

  const goto = (site, dataToPass = {}, e) => {
    if (e) e.preventDefault();
    setDataToPass(dataToPass);
    setSearchParams({ site });
    setCurrentSite(site);
  };

  const sitesRender = [];
  Object.keys(sites).forEach((site) => {
    if (sites[site].hidden) return;
    sitesRender.push(
      <a
        key={site}
        href={`?site=${site}`}
        className={`Site ${currentSite === site || sites[currentSite].fake === site
          ? "Active"
          : ""
          }`}
        onClick={(e) => goto(site, {}, e)}
      >
        {sites[site].title}
      </a>
    );
  });

  React.useEffect(() => global.setLoadingVisible(false), []);

  return (
    <div className="Admin">
      <div className="Side-panel">
        <div className="Sites">{sitesRender}</div>
        <a href="/api/user/logout">Wyloguj</a>
      </div>
      <div className="Main-panel">
        <div className="Content">{sites[currentSite].content}</div>
        <div className="Footer">
          <a href="/dn/privacy.pdf">Polityka prywatności</a>
          <a href="/dn/report.pdf">Procedura zgłaszania</a>
          <a href="/">Użytkownik</a>
          {/* <a href="/">Utwórz profil firmy</a> */}
          <a className="mobile" href="/api/user/logout">
            Wyloguj
          </a>
        </div>
      </div>
    </div>
  );
}

export default Admin;
