import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./stylesheets/index.css";
import App from "./routes/App";
import Admin from "./routes/Admin";
import Tracker from "./routes/Tracker";
import reportWebVitals from "./reportWebVitals";
import Outer from "./Outer";
import Auth from "./routes/Auth";
import config from "./config.json";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (config.api.offline)
  console.warn(
    "%cRunning in offline mode! API integration is disabled.\nUse for development purposes only.",
    "background: red; color: yellow; font-size: x-large"
  );

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Outer />}>
        <Route index element={<App />} />
        <Route path="admin" element={<Admin />} />
        <Route path="tracker" element={<Tracker />} />
        <Route path="auth" element={<Auth />} />
        {/* <Route path="*" element={<NoPage />} /> */}
      </Route>
    </Routes>
  </BrowserRouter>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
