import React from "react";
import "./stylesheets/App.css";
import "./stylesheets/Loading.css";
import { Outlet } from "react-router-dom";

let initialIsLoadingVisible = true;
global.setLoadingVisible = (visible = true) => {
  initialIsLoadingVisible = visible;
};

const defaultModalData = {
  question: "",
  buttons: [
    {
      name: "Tak",
      action: () => {},
    },
    {
      name: "Nie",
      action: () => {},
    },
  ],
};
function Outer(content) {
  const [showLoading, setLoading] = React.useState(initialIsLoadingVisible);
  React.useEffect(() => {
    setTimeout(() => setLoading(initialIsLoadingVisible), 100);
    global.setLoadingVisible = (visible = true) => {
      initialIsLoadingVisible = visible;
      setLoading(visible);
    };
  }, [setLoading]);
  const [modalData, setModalData] = React.useState(defaultModalData);
  const ask = (data = defaultModalData) => {
    document.querySelector(".Modal-outer").style.display = "block";
    setModalData(data);
  };
  global.ask = ask;
  return (
    <div className="App">
      <div className="Modal-outer">
        <div className="Modal">
          <p>{modalData.question}</p>
          <div className="Buttons">
            {modalData.buttons.map((button) => (
              <button
                key={button.name}
                onClick={() => {
                  document.querySelector(".Modal-outer").style.display = "none";
                  button.action();
                }}
              >
                {button.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        className="Modal-outer"
        style={{ display: showLoading ? "block" : "none" }}
      >
        <div className="Loading">
          <div className="lds-dual-ring"></div>
          {/* <div className="lds-circle">
            <div></div>
          </div> */}
          {/* <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div> */}
          {/* <div className="lds-ripple">
            <div></div>
            <div></div>
          </div> */}
          <h2>Ładowanie</h2>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Outer;
