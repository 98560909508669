import React, { useEffect } from "react";
import "../stylesheets/Admin.css";
import "../stylesheets/Auth.css";
import config from "../config.json";

function Auth() {
  useEffect(() => {
    global.loading = false;
  }, []);

  const [text, setText] = React.useState(null);

  React.useEffect(() => global.setLoadingVisible(false), []);

  return (
    <div className="App">
      <div className="Modal-outer Auth-mod-outer">
        <div className="Modal Auth-mod">
          <h1 className={text ? "Error" : "Normal"}>
            {text ? (
              text
            ) : (
              <>
                Aby przejść do panelu zarządzania <b>zaloguj się</b>
              </>
            )}
          </h1>
          <form
            id="login"
            name="login"
            onSubmit={(e) => {
              e.preventDefault();
              global.loading = true;
              fetch(`${config.api.url}/user/auth`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  username: document.getElementById("username").value,
                  password: document.getElementById("password").value,
                }),
              })
                .then((result) => {
                  if (result.status === 401)
                    setText("Nieprawidłowa nazwa użytkownika lub hasło");
                  else if (result.ok) document.location.href = "/admin";
                  else console.error("Failed to login", result);
                  global.loading = false;
                })
                .catch((result) => {
                  if (result.status === 401)
                    setText("Nieprawidłowa nazwa użytkownika lub hasło");
                  else console.error("Failed to login", result);
                });
            }}
          >
            <input
              type="username"
              id="username"
              name="username"
              placeholder="Nazwa użytkownika"
            />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Hasło"
            />
          </form>
          <div className="Buttons">
            <button type="submit" form="login">
              Zaloguj się
            </button>
          </div>
        </div>
      </div>
      <div className="Side-panel">
        <p className="Mock"></p>
        <a href="/">Użytkownik</a>
      </div>
      <div className="Main-panel">
        <div className="Content"></div>
        <div className="Footer">
          <a href="/dn/privacy.pdf">Polityka prywatności</a>
          <a href="/dn/report.pdf">Procedura zgłaszania</a>
          <a href="/">Utwórz profil firmy</a>
        </div>
      </div>
    </div>
  );
}

export default Auth;
