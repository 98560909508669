import React from "react";
import { AudioRecorder } from 'react-audio-voice-recorder';

import "../stylesheets/App.css";
import config from "../config.json";

function App() {
  const [companies, setCompanies] = React.useState([]);
  const [voiceReport, setVoiceReport] = React.useState(false);
  const [audioBlob, setAudioBlob] = React.useState(null);
  const reloadCompanies = async () => {
    if (config.api.offline) {
      setCompanies([
        { id: 1, name: "Firma 1", admin: 1 },
        { id: 2, name: "Firma 2", admin: 1 },
        { id: 3, name: "Firma 3", admin: 1 },
      ]);
      return;
    }
    global.setLoadingVisible(true);
    try {
      const result = await fetch(`${config.api.url}/companies`);
      if (result.ok) setCompanies(await result.json());
      else console.error("Failed to fetch companies", result);
    } catch (result) {
      console.error("Failed to fetch companies", result);
    }
    global.setLoadingVisible(false);
  };
  React.useEffect(() => {
    reloadCompanies();
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const [company, setCompany] = React.useState(null);
  const [companyRelation, setCompanyRelation] = React.useState(null);

  const [contactData, setContactData] = React.useState({
    name: "",
    phone: "",
    mail: "",
  });
  const [description, setDescription] = React.useState("");

  const [attachments, setAttachments] = React.useState([]);

  const handleUploadError = async (res) => {
    let content = await res.json();
    if (content.error?.code === "LIMIT_FILE_SIZE") {
      global.ask({
        question: "Plik przekracza maksymalny rozmiar załącznika.",
        buttons: [{ name: "OK", action: () => { } }],
      });
      global.setLoadingVisible(false);
    } else {
      global.ask({
        question: "Nie udało się dodać załącznika. Spróbuj ponownie później.",
        buttons: [{ name: "OK", action: () => { } }],
      });
      console.error("Failed to upload attachment", res, content);
      global.setLoadingVisible(false);
    }
  };

  const onFormSubmit = async () => {
    if (config.api.offline) return;
    global.setLoadingVisible(true);
    let voiceReportName = null;
    if (voiceReport && audioBlob) {
      const formData = new FormData();
      formData.append("file", audioBlob, "voice-report.webm");
      const res = await fetch(`${config.api.url}/upload`, {
        method: "PUT",
        body: formData,
      }).catch((e) => {
        handleUploadError(e);
      });
      if (res.ok) {
        voiceReportName = (await res.json()).name;
      } else handleUploadError(res);
    }
    const res = await fetch(`${config.api.url}/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: company,
        companyRelation,
        contact:
          Object.values(contactData).filter((v) => v.length > 0).length > 0
            ? `Imię i nazwisko:\n${contactData.name.length > 0
              ? contactData.name
              : "Nie udzielono informacji"
            }\n\nNumer telefonu:\n${contactData.phone.length > 0
              ? contactData.phone
              : "Nie udzielono informacji"
            }\n\nAdres e-mail:\n${contactData.mail.length > 0
              ? contactData.mail
              : "Nie udzielono informacji"
            }`
            : null,
        content: voiceReportName ? `Zgłoszenie głosowe ${voiceReportName}` : description,
        attachments: JSON.stringify([voiceReportName, ...attachments]),
      }),
    }).catch((e) => {
      global.ask({
        question:
          "Nie udało się wysłać zgłoszenia. Spróbuj ponownie później.",
        buttons: [{ name: "OK", action: () => { } }],
      });
      console.error("Failed to send report", e);
      global.setLoadingVisible(false);
    });
    if (!res.ok) {
      global.ask({
        question:
          "Nie udało się wysłać zgłoszenia. Spróbuj ponownie później.",
        buttons: [{ name: "OK", action: () => { } }],
      });
      console.error("Failed to send report", res.status);
      global.setLoadingVisible(false);
      return;
    }
    const code = (await res.json()).code;
    document.location = `https://report.afilidia.com/tracker?code=${code}`;
    global.setLoadingVisible(false);
  }

  const onAudioRecordSave = (blob) => {
    setAudioBlob(blob);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.querySelector("#audio").innerHTML = "";
    document.querySelector("#audio").appendChild(audio);
  };

  React.useEffect(() => global.setLoadingVisible(false), []);

  return (
    <div className="New-App">
      <div className="Header">
        <p>System zgłaszania</p>
        <div></div>
        <div></div>
        <div className="Button">
          <div></div>
          <button onClick={() => document.location = "/"}>Utwórz zgłoszenie</button>
        </div>
      </div>
      <div className="Row">
        <div className="Left-Column">
          <h1>Utwórz zgłoszenie</h1>
          <p>By łatwo i bezpiecznie złożyć zgłoszenie należy postępować zgodnie z instrukcjami zawartymi w formularzu. Po przesłaniu formularza na ekranie pojawi się nazwa użytkownika oraz hasło osoby zgłaszającej. Dane te generowane są dla zgłoszenia, dlatego celem kontynuowania komunikacji należy zapisać je w bezpieczny sposób, umożliwiający ich odtworzenie (np. notatki, printscreen ekranu). Nigdy nie prosimy o podanie tożsamości osoby składającej zgłoszenie. Anonimowość osoby składającej zgłoszenie będzie zachowana podczas całej procedury.</p>
          <div className="Row">
            <div className="Half">
              <h2>Masz otwarte zgłoszenie?</h2>
              <p>Wjedź na link, który dostałeś po wysłaniu zgłoszenia</p>
            </div>
            <div className="Half">
              <h2>Zgłoszenie głosowe</h2>
              <p>Nagraj swoje zgłoszenie</p>
              <div className="Button">
                <div></div>
                <button onClick={() => setVoiceReport(!voiceReport)}>Zgłoszenie głosowe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="Right-Column">
          <div className="Row">
            <div className="Half">
              <label>Miejsce zdarzenia</label>
              <select
                className="Input"
                value={company || "0"}
                onChange={(e) => setCompany(e.target.value)}
              >
                <option className="Display-none" value="0" disabled defaultChecked></option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="Half">
              <label>
                Jaka jest twoja relacja z firmą?
              </label>
              <select
                className="Input"
                value={companyRelation || "0"}
                onChange={(e) => setCompanyRelation(e.target.value)}
              >
                <option className="Display-none" value="0" disabled defaultChecked></option>
                <option value="1">Pracownik</option>
                <option value="2">Dostawca</option>
                <option value="3">Inne</option>
              </select>
            </div>
          </div>
          <div className="Row Flex-1">
            {/* <div
              className="textarea"
              contentEditable="plaintext-only"
              placeholder="Pisz tutaj..."
              value={description}
              onInput={(e) => {
                setDescription(e.currentTarget.textContent);
              }}
            /> */}
            {voiceReport ? (
              <div className="Left-content">
                <div className="Voice-Report">
                  <AudioRecorder
                    onRecordingComplete={onAudioRecordSave}
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                    downloadFileExtension="webm"
                    downloadOnSavePress={false}
                  />
                  <label>Naciśnij przycisk powyżej, aby {audioBlob ? "ponowić" : "rozpocząć"} nagrywanie</label>
                  <div id="audio"></div>
                </div>
              </div>
            ) : (
              <div className="Left-content">
                <label>Opis zdarzenia</label>
                <textarea className="Input" value={description} onChange={e => setDescription(e.target.value)}></textarea>
              </div>
            )}
            <div className="Right-content">
              <label>
                Dane kontaktowe (opcjonalne)
              </label>
              {/* <div
            className="textarea"
            contentEditable="plaintext-only"
            placeholder="Pisz tutaj..."
            value={contactData}
            onInput={(e) => {
              setContactData(e.currentTarget.textContent);
            }}
          /> */}
              <input
                className="Input"
                type="text"
                placeholder="Imię i nazwisko"
                value={contactData.name}
                onChange={(e) =>
                  setContactData({ ...contactData, name: e.target.value })
                }
              />
              <input
                className="Input"
                type="tel"
                placeholder="Numer telefonu"
                value={contactData.phone}
                onChange={(e) =>
                  setContactData({ ...contactData, phone: e.target.value })
                }
              />
              <input
                className="Input"
                type="email"
                placeholder="Adres e-mail"
                value={contactData.mail}
                style={
                  contactData.mail && !isValidEmail(contactData.mail)
                    ? { borderColor: "red" }
                    : {}
                }
                onChange={(e) => {
                  setContactData({ ...contactData, mail: e.target.value });
                }}
              />
              <br />
              <input
                type="file"
                id="addFile"
                name="attachment"
                style={{ display: "none" }}
                onChange={async (e) => {
                  if (config.api.offline) return;
                  global.setLoadingVisible(true);
                  const formData = new FormData();
                  formData.append("file", e.currentTarget.files[0]);
                  const res = await fetch(`${config.api.url}/upload`, {
                    method: "PUT",
                    body: formData,
                  }).catch((e) => {
                    handleUploadError(e);
                  });
                  if (res.ok) {
                    setAttachments([...attachments, (await res.json()).name]);
                    global.setLoadingVisible(false);
                  } else handleUploadError(res);
                  e.target.value = null;
                }}
              />

              <div className="Row">
                <label>Załączniki</label>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("addFile").click();
                  }}
                >
                  Dodaj (maks. 20MB)
                </a>
              </div>
              <div className="Attachments-list">
                {attachments.map((attachment, i) => (
                  <div key={i} className="Attachment">
                    <p>Załącznik {attachment}{" "}</p>
                    <a
                      href={`${config.api.url}/../dn/attachments/${attachment}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pobierz
                    </a>{" "}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setAttachments(attachments.filter((_, j) => i !== j));
                      }}
                    >
                      Usuń
                    </a>
                  </div>
                ))}
              </div>
              <div className={"Button Gray" + ((
                ((voiceReport && !audioBlob) || (!voiceReport && description.length < 10)) ||
                !company ||
                company === 0 ||
                company === "000" ||
                !companyRelation ||
                companyRelation === 0 ||
                companyRelation === "000") ? " Inactive" : "")}>
                <div></div>
                <button disabled={((voiceReport && !audioBlob) || (!voiceReport && description.length < 10)) ||
                  !company ||
                  company === 0 ||
                  company === "000" ||
                  !companyRelation ||
                  companyRelation === 0 ||
                  companyRelation === "000"} onClick={onFormSubmit}>Wyślij zgłoszenie</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer">
        <a href="/dn/privacy.pdf">Polityka prywatności</a>
        <a href="/dn/procedura.pdf">Procedura zgłaszania</a>
        <a href="/dn/report.pdf">Instrukcja zgłaszania</a>
        <a href="/admin" className="mobile">
          Administrator
        </a>
        <div className="Button">
          <div></div>
          <button onClick={() => document.location = "/"}>Utwórz zgłoszenie</button>
        </div>
      </div>
    </div>
  );
}

export default App;
